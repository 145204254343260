.image-upload-form {
  margin: auto;
  width: 50%;

  &-input {
    padding-bottom: 20px;
  }

  &-buttons {
    display: inline-flex;
    gap: 20px;
    width: 100%;
  }
}
