.camera {
  margin: auto;
  margin-top: 20px;
  width: 50%;

  h3 {
    margin: 0 0 8px;
    text-align: center;
  }

  &-captured-image,
  &-preview-image {
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 4px;
    width: 98%;
  }

  &-captured-image {
    &-container {
      display: inline-block;
      position: relative;
      text-align: center;
      width: 100%;
    }

    &-controls {
      &-button {
        @mixin base {
          height: 60px;
          margin: 16px;
          width: 60px;
        }

        &-yes {
          @include base;

          path {
            fill: #2aaf5b !important;
          }
        }

        &-no {
          @include base;

          path {
            fill: #f44336 !important;
          }
        }
      }
    }
  }

  &-note {
    margin-bottom: 20px;
    text-align: center;
  }

  &-buttons {
    align-items: center;
    display: inline-flex;
    gap: 40px;
    justify-content: center;
    width: 100%;
  }

  &-take-picture-button {
    border: 3px solid #2a5fc9;
    border-radius: 50%;
    height: 50px;
    text-align: center;
    width: 50px;

    svg {
      margin-top: 8px;
    }
  }

  &-preview-image-container {
    text-align: center;
    width: 100%;
  }
}
