@font-face {
  font-family: WilliamHill;
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/william-hill-regular.woff") format("woff");
}

@font-face {
  font-family: WilliamHillBold;
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/william-hill-bold.woff") format("woff");
}

@font-face {
  font-family: WilliamHillLight;
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/william-hill-light.woff") format("woff");
}

body {
  background-color: #f2f4f7;
  font-family: WilliamHill, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
